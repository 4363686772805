<template>
  <v-form
    ref="form"
    :value="allValid"
    @input="$emit('update:all-valid', $event)"
  >
    <v-container
      :class="[formHorizPad, 'py-4', 'batch-form']"
      class="container-public-repo"
    >
      <h2 class="header-vertical-padding custom-font header-size">
        {{ !$language ? "Details" : "বিবরণ" }}
      </h2>
      <v-row class="mt-3">
        <v-col
          :cols="colsTop"
          :class="[{ 'pr-7': screenSize >= 900 }, { 'py-0': screenSize < 900 }]"
        >
          <v-text-field
            v-model="batchData.title"
            :dense="screenSize < 600"
            :class="['py-0', { 'read-only-class': readOnly }]"
            outlined
            :label="!$language ? 'Batch Title' : 'টাইটেল'"
            :placeholder="
              !$language
                ? 'Enter your batch title here...'
                : 'আপনার ব্যাচ টাইটেল লিখুন'
            "
            counter="32"
            maxlength="32"
            data-qa="batch-title"
            :rules="rules.title"
          ></v-text-field>
        </v-col>
        <v-col
          :cols="colsTop"
          :class="[{ 'pl-7': screenSize >= 900 }, { 'py-0': screenSize < 900 }]"
        >
          <v-select
            v-model="batchData.teacher_id"
            :dense="screenSize < 600"
            :class="[{ 'read-only-class': readOnly }]"
            outlined
            :label="!$language ? 'Teacher' : 'শিক্ষক'"
            :placeholder="
              !$language ? 'Select teacher...' : 'শিক্ষক নির্বাচন করুন'
            "
            :items="teachers"
            :loading="teacherLoading"
            :rules="rules.required"
          ></v-select>
        </v-col>
      </v-row>
      <v-row data-qa="batch-summary-row">
        <v-col cols="12" :class="[{ 'py-0': screenSize < 900 }]">
          <v-textarea
            v-model="batchData.summary"
            :dense="screenSize < 600"
            :class="[{ 'read-only-class': readOnly }]"
            outlined
            auto-grow
            clear-icon="close"
            :label="!$language ? 'Summary' : 'সারসংক্ষেপ'"
            :placeholder="
              !$language
                ? 'Enter batch summary in 3 lines...'
                : '৩ লাইনের মধ্যে ব্যাচের সারাংশ লিখুন'
            "
            counter="128"
            maxlength="128"
            rows="3"
            data-qa="batch-summary"
            :rules="rules.summary"
          ></v-textarea>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" :class="[{ 'py-0': screenSize < 900 }]">
          <v-textarea
            v-model="batchData.description"
            :dense="screenSize < 600"
            :class="[{ 'read-only-class': readOnly }]"
            outlined
            auto-grow
            clear-icon="close"
            :label="!$language ? 'Description' : 'বিবরণ'"
            :placeholder="
              !$language
                ? 'Enter your batch description here...'
                : 'এখানে আপনার ব্যাচের বিবরণ লিখুন'
            "
            counter="8196"
            maxlength="8196"
            :rules="rules.description"
            data-qa="batch-desc"
          ></v-textarea>
        </v-col>
      </v-row>
      <v-row class="d-flex justify-end">
        <v-col
          :cols="colsMid"
          :class="[
            { 'py-0': screenSize < 900 },
            { 'pr-0': screenSize < 900 && screenSize > 600 }
          ]"
        >
          <v-select
            v-model="batchData.batch_type"
            :dense="screenSize < 600"
            :class="[clsBatchType, { 'read-only-class': readOnly }]"
            :items="batch_type"
            :item-text="!$language ? 'en' : 'bn'"
            item-value="en"
            :placeholder="
              !$language
                ? 'Select your batch type'
                : 'আপনার ব্যাচ টাইপ নির্বাচন করুন'
            "
            :label="!$language ? 'Batch Type' : 'ব্যাচ টাইপ'"
            outlined
            height="45"
            :rules="rules.required"
            data-qa="batch-type"
          >
          </v-select>
        </v-col>
        <v-col
          :cols="colsMid"
          :class="[
            { 'py-0': screenSize < 900 },
            { 'px-0': screenSize < 900 && screenSize > 600 }
          ]"
        >
          <v-menu
            v-model="showPaymentTypeMenu"
            :close-on-content-click="false"
            transition="slide-y-transition"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                :value="pMethod"
                :placeholder="
                  !$language
                    ? 'Select your payment method'
                    : 'আপনার পেমেন্ট মেথড নির্বাচন করুন'
                "
                outlined
                readonly
                height="45"
                :label="!$language ? 'Payment Type' : 'পেমেন্ট টাইপ'"
                persistent-hint
                :dense="screenSize < 600"
                :class="[
                  { 'pl-7': screenSize >= 900 },
                  { 'read-only-class': readOnly }
                ]"
                :rules="rules.required"
                v-on="on"
              ></v-text-field>
            </template>
            <v-expansion-panels
              v-model="payment"
              flat
              accordion
              focusable
              class="pa-0"
            >
              <v-expansion-panel
                readonly
                @click="setPaymentType('One Time', 0)"
              >
                <v-expansion-panel-header expand-icon="">
                  {{ !$language ? "One Time" : "এককালীন" }}
                </v-expansion-panel-header>
              </v-expansion-panel>
              <v-expansion-panel readonly @click="setPaymentType('Monthly', 1)">
                <v-expansion-panel-header expand-icon="">
                  {{ !$language ? "Monthly" : "মাসিক" }}
                </v-expansion-panel-header>
              </v-expansion-panel>
              <v-expansion-panel readonly @click="setPaymentType('Yearly', 2)">
                <v-expansion-panel-header expand-icon="">
                  {{ !$language ? "Yearly" : "বাৎসরিক" }}
                </v-expansion-panel-header>
              </v-expansion-panel>
              <v-expansion-panel @click="setPaymentType(0, 3)">
                <v-expansion-panel-header
                  >{{ !$language ? "Installment" : "কিস্তি" }}
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-slider
                    v-model="installmentOptions"
                    hide-details="true"
                    color="#0099dc"
                    thumb-label="always"
                    min="2"
                    max="9"
                  >
                  </v-slider>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-menu>
        </v-col>
        <v-col
          :cols="colsMid"
          :class="[
            { 'py-0': screenSize < 900 },
            { 'pl-0': screenSize < 900 && screenSize > 600 }
          ]"
        >
          <v-text-field
            v-model="batchData.price"
            :dense="screenSize < 600"
            :class="[clsPrice, { 'read-only-class': readOnly }]"
            :rules="rules.batchPrice"
            type="number"
            outlined
            :label="!$language ? 'Price' : 'মূল্য'"
            :placeholder="
              !$language
                ? 'Enter the cost to join(optional)...'
                : 'যোগদানের খরচ লিখুন (ঐচ্ছিক)'
            "
            height="45"
            hide-spin-buttons
          ></v-text-field>
        </v-col>
      </v-row>

      <div class="content-header mt-3 mb-1 custom-font avail-sched">
        {{ !$language ? "Availability" : "অ্যাভেইলাবিলিটি" }}
      </div>

      <v-row>
        <v-col :cols="colsTop">
          <v-menu
            v-model="beginDateMenu"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                ref="begin"
                v-model="beginDateFormatted"
                :dense="screenSize < 600"
                :hide-details="screenSize < 600"
                :class="[
                  { 'pr-7': screenSize >= 900 },
                  { 'read-only-class': readOnly }
                ]"
                outlined
                height="45"
                :label="!$language ? 'Begin Date' : 'শুরুর তারিখ'"
                placeholder="dd/mm/yyyy"
                persistent-hint
                clearable
                :rules="rules.begindate"
                data-qa="batch-begin-date"
                v-on="on"
                @click:clear="batchData.begin_date = undefined"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="batchData.begin_date"
              no-title
              scrollable
              @input="beginDateMenu = false"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col :cols="colsTop">
          <v-menu
            v-model="endDateMenu"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                ref="end"
                v-model="endDateFormatted"
                :dense="screenSize < 600"
                :hide-details="screenSize < 600"
                :class="[
                  { 'pl-7': screenSize >= 900 },
                  { 'read-only-class': readOnly }
                ]"
                outlined
                height="45"
                :label="!$language ? 'End Date' : 'শেষ তারিখ'"
                persistent-hint
                clearable
                placeholder="dd/mm/yyyy"
                :rules="rules.enddate"
                data-qa="batch-end-date"
                v-on="on"
                @click:clear="batchData.end_date = undefined"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="batchData.end_date"
              no-title
              scrollable
              @input="endDateMenu = false"
            ></v-date-picker>
          </v-menu>
        </v-col>
      </v-row>

      <div>
        <div class="content-header mt-5 mb-1">
          <h3 class="custom-font avail-sched">
            {{ !$language ? "Schedule" : "শিডিউল" }}
          </h3>
        </div>
        <div class="d-flex">
          <m-button
            :disabled="readOnly"
            rounded
            text
            color="primary"
            class="mt-2"
            @click="showSchedule"
          >
            <span v-if="!scheduleVisibility">
              <v-icon left>add</v-icon>
              <span class="custom-font add-cancel-text">{{
                !$language ? "Add schedule" : "শিডিউল যোগ করুন"
              }}</span></span
            >
            <span v-else>
              <v-icon left>edit</v-icon>
              <span class="custom-font add-cancel-text">{{
                !$language ? "Edit schedule" : "শিডিউল এডিট করুন"
              }}</span>
            </span>
          </m-button>
          <v-spacer></v-spacer>
          <m-button
            v-if="hideSchedule === false && !readOnly"
            rounded
            text
            color="red accent-3"
            class="mt-2 add-cancel-text"
            @click="clearSchedule"
          >
            <v-icon left>cancel</v-icon>
            {{ !$language ? "Cancel" : "বাতিল করুন" }}
          </m-button>
        </div>

        <v-container v-if="scheduleVisibility" class="schedule-picker">
          <v-row>
            <v-col class="d-flex justify-end pr-10">
              <v-btn color="#0099dc" icon @click="toggleSchedule">
                <v-icon v-show="!compressSchedule">mdi-menu-up</v-icon>
                <span v-show="!compressSchedule" class="caption">{{
                  !$language ? "Hide" : "সংকুচিত করুন"
                }}</span>
                <v-icon v-show="compressSchedule">mdi-menu-down</v-icon>
                <span v-show="compressSchedule" class="caption">{{
                  !$language ? "Expand" : "প্রসারিত করুন"
                }}</span>
              </v-btn>
            </v-col>
          </v-row>
          <v-row v-show="compressSchedule === false" class="flex-column">
            <v-col>
              <div v-for="(_schedule, day) in schedule" :key="day">
                <v-row class="d-flex align-center justify-start mt-5">
                  <v-col
                    :cols="screenSize < 600 ? 4 : 3"
                    class="day-text d-flex justify-center"
                    :class="{ 'mb-7': screenSize >= 600 }"
                    >{{ !$language ? day : weekDayBN(day) }}:
                  </v-col>
                  <v-col :cols="screenSize < 600 ? 5 : 6" class="py-3 day-text">
                    <v-menu
                      v-model="timePicker[day]"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      class="time-picker-menu"
                      attach=".schedule-picker"
                    >
                      <template v-slot:activator="{ on }">
                        <div class="time-picker-outline">
                          <v-text-field
                            v-if="screenSize >= 600"
                            :class="{ 'read-only-class': readOnly }"
                            :value="_schedule"
                            :placeholder="
                              !$language
                                ? 'click to add time'
                                : 'সময় যোগ করতে এখানে ক্লিক করুন'
                            "
                            prepend-inner-icon="access_time"
                            outlined
                            v-on="on"
                            :rules="[rules.schedule(day)]"
                          >
                          </v-text-field>
                          <v-text-field
                            v-else
                            :class="{ 'read-only-class': readOnly }"
                            :value="_schedule"
                            dense
                            :placeholder="
                              !$language
                                ? 'click to add time'
                                : 'সময় যোগ করতে এখানে ক্লিক করুন'
                            "
                            outlined
                            hide-details="true"
                            v-on="on"
                          >
                          </v-text-field>
                        </div>
                      </template>
                      <v-card>
                        <v-card-text class="py-0 pb-2">
                          <v-row class="my-0">
                            <v-col :cols="screenSize < 900 ? '12' : ''">
                              <time-picker
                                v-model="start_time[day]"
                                label="From:"
                              ></time-picker>
                              <v-divider v-if="screenSize < 900"></v-divider>
                            </v-col>
                            <v-divider
                              v-if="screenSize >= 900"
                              vertical
                              class="mt-4"
                            ></v-divider>
                            <v-col :cols="screenSize < 900 ? '12' : ''">
                              <time-picker
                                v-model="end_time[day]"
                                label="To:"
                              ></time-picker>
                            </v-col>
                          </v-row>
                          <v-container>
                            <v-row no-gutters justify="center">
                              <v-btn @click="timePicker[day] = false"
                                >{{ !$language ? "Cancel" : "বাতিল করুন" }}
                              </v-btn>
                              <v-btn
                                :disabled="readOnly"
                                depressed
                                color="primary"
                                class="ml-4"
                                min-width="160"
                                @click="addClass(day)"
                                >{{ !$language ? "Add Time" : "সময় যোগ করুন" }}
                              </v-btn>
                            </v-row>
                          </v-container>
                        </v-card-text>
                      </v-card>
                    </v-menu>
                  </v-col>

                  <v-col
                    v-if="showRemoveClass[day]"
                    cols="2"
                    class="d-flex justify-end"
                  >
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          :disabled="readOnly"
                          icon
                          color="red accent-2"
                          :class="{ 'mb-7': screenSize >= 600 }"
                          v-bind="attrs"
                          @click="removeClass(day)"
                          v-on="on"
                        >
                          <v-icon>delete</v-icon>
                        </v-btn>
                      </template>
                      <span>{{
                        !$language ? "Delete Schedule" : "শিডিউল ডিলিট করুন"
                      }}</span>
                    </v-tooltip>
                  </v-col>
                </v-row>
                <v-divider v-if="day !== 'Friday'"></v-divider>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </div>
    </v-container>
  </v-form>
</template>

<script>
import TimePicker from "#ecf/batch/components/TimePicker";
import { cloneDeep, pickBy } from "lodash";
import { FieldValidations } from "/global/utils/validations.js";

export default {
  name: "BatchForm",
  components: { TimePicker },
  mixins: [FieldValidations],
  model: {
    prop: "batch"
  },
  props: {
    batch: {
      type: Object,
      required: true
    },
    allValid: {
      type: Boolean,
      default: true
    },
    readOnly: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      showPaymentTypeMenu: false,
      hideSchedule: true,
      compressSchedule: true,
      showRemoveClass: {},
      teachers: [],
      teacherLoading: false,
      beginDateFormatted: undefined,
      endDateFormatted: undefined,
      classPicker: {},
      timePicker: {},
      start_time: {},
      end_time: {},
      beginDateMenu: false,
      endDateMenu: false,
      batchData: cloneDeep(this.batch),
      batch_type: [
        { en: "Online", bn: "অনলাইন" },
        { en: "In Person", bn: "ব্যক্তিগত ভাবে" }
      ],
      originalData: cloneDeep(this.batch),
      payment: [],
      installmentOptions: 0,
      installmentStage: false,
      payment_method_obj: {
        "One Time": "এককালীন",
        Monthly: "মাসিক",
        Yearly: "বাৎসরিক",
        Installment: "কিস্তি"
      }
    };
  },
  computed: {
    pMethod() {
      let p_type = this.batchData.payment_type;
      if (p_type === undefined) {
        return "";
      }
      if (this.$language) {
        let pTypeSplitData = p_type.split("-");
        p_type = this.payment_method_obj[pTypeSplitData[0]];
        if (pTypeSplitData.length > 1) {
          return `${p_type}-${pTypeSplitData[1]}`;
        }
      }
      return p_type;
    },
    screenSize() {
      return this.$vuetify.breakpoint.width;
    },
    formHorizPad() {
      return this.screenSize < 600
        ? "px-4"
        : this.screenSize < 900
        ? "px-7"
        : this.screenSize < 1600
        ? "px-11"
        : "px-15";
    },
    colsTop() {
      return this.screenSize < 600 ? 12 : 6;
    },
    colsMid() {
      return this.screenSize < 600 ? 12 : this.screenSize < 900 ? 4 : 6;
    },
    colsMidPay() {
      return this.screenSize < 600 ? 6 : this.screenSize < 900 ? 4 : 6;
    },
    clsBatchType() {
      return this.screenSize < 600
        ? ""
        : this.screenSize < 900
        ? "pr-2"
        : "pr-7";
    },
    clsPaymentType() {
      return this.screenSize < 600
        ? ""
        : this.screenSize < 900
        ? "px-2"
        : "pl-7";
    },
    clsPrice() {
      return this.screenSize < 600
        ? ""
        : this.screenSize < 900
        ? "pl-2"
        : "pl-7";
    },
    greaterDate() {
      if (!this.beginDateFormatted || !this.endDateFormatted) return true;
      let [d1, m1, y1] = this.beginDateFormatted.split("/");
      let [d2, m2, y2] = this.endDateFormatted.split("/");
      if (y1 === y2) {
        if (m1 === m2) {
          return d1 < d2;
        }
        return m1 < m2;
      }
      return y1 < y2;
    },
    endDateAfterToday() {
      if (!this.endDateFormatted) return true;
      let [d2, m2, y2] = this.endDateFormatted.split("/");
      let today = new Date();
      let d1 = String(today.getDate()).padStart(2, "0");
      let m1 = String(today.getMonth() + 1).padStart(2, "0");
      let y1 = String(today.getFullYear());

      if (y1 === y2) {
        if (m1 === m2) {
          return d1 < d2;
        }
        return m1 < m2;
      }
      return y1 < y2;
    },
    rules() {
      return {
        title: [this.required, v => this.minLength(v?.trim(), 4, "Title")],
        summary: [this.required, v => this.minLength(v?.trim(), 10, "Summary")],
        description: [
          this.required,
          v => this.minLength(v?.trim(), 10, "Description")
        ],
        required: [this.required],
        batchPrice: [
          this.required,
          v => this.minValue(v, 0, "Batch price"),
          v => this.floatOnly(v, "Batch price")
        ],
        begindate: [
          this.required,
          () => this.greaterDate || "Begin Date must be before End date"
        ],
        enddate: [
          this.required,
          () => this.greaterDate || "Begin Date must be before End date",
          () => this.endDateAfterToday || "End date must be valid"
        ],
        schedule: day => {
          return this.start_end_time_delta(day) < 0
            ? () => true
            : () => "start time must be before end time";
        }
      };
    },
    schedule() {
      const _schedule = {};
      for (let day of Object.keys(this.batchData.schedule)) {
        _schedule[day] = this.batchData.schedule[day]
          ? `From ${this.formatTime(
              this.batchData.schedule[day].from
            )} To ${this.formatTime(this.batchData.schedule[day].to)}`
          : undefined;
      }
      return _schedule;
    },
    scheduleVisibility() {
      let flag = false;
      for (let day of Object.keys(this.batchData.schedule)) {
        if (this.batchData.schedule[day] !== undefined) {
          flag = true;
          break;
        }
      }
      if (this.readOnly) {
        return flag;
      }
      if (flag) {
        return flag;
      } else {
        return !this.hideSchedule;
      }
    }
  },
  watch: {
    "batchData.begin_date": {
      handler(newValue) {
        this.beginDateFormatted = this.formatDate(this.batchData.begin_date);
      },
      immediate: true
    },
    "batchData.end_date": {
      handler(newValue) {
        this.endDateFormatted = this.formatDate(this.batchData.end_date);
      },
      immediate: true
    },
    greaterDate: {
      handler() {
        this.$refs.begin.validate();
        this.$refs.end.validate();
      }
    },
    endDateAfterToday: {
      handler() {
        this.$refs.begin.validate();
        this.$refs.end.validate();
      }
    },
    batchData: {
      deep: true,
      handler() {
        this.$emit("input", this.batchData);
      }
    },
    showPaymentTypeMenu: {
      handler(value) {
        if (this.installmentStage) {
          this.batchData.payment_type = `Installment-${this.installmentOptions}`;
        }
      }
    }
  },
  async created() {
    this.checkForSchedule();
    await this.getTeacherNames();
  },
  methods: {
    getKeyByValue(object, value) {
      return Object.keys(object).find(key => object[key] === value);
    },

    weekDayBN(value) {
      if (value === "Saturday") return "শনিবার";
      else if (value === "Sunday") return "রবিবার";
      else if (value === "Monday") return "সোমবার";
      else if (value === "Tuesday") return "মঙ্গলবার";
      else if (value === "Wednesday") return "বুধবার";
      else if (value === "Thursday") return "বৃহস্পতিবার";
      else if (value === "Friday") return "শুক্রবার";
      else return "";
    },
    setPaymentType(type, idx) {
      if (type == 0) {
        this.installmentStage = true;
      } else {
        this.payment = idx;
        this.installmentStage = false;
        this.batchData.payment_type = type;
        this.showPaymentTypeMenu = false;
      }
    },
    formatTime(time) {
      if (!time) return "";
      return `${time.hour}:${time.minute == 0 ? "00" : time.minute} ${
        time.amPm
      }`;
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    parseDate(date) {
      if (!date) return null;

      const [day, month, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
    start_end_time_delta(day) {
      if (
        this.start_time[day] === undefined ||
        this.end_time[day] === undefined
      )
        return -1;
      let st = 60 * this.start_time[day].hour + this.start_time[day].minute;
      let et = 60 * this.end_time[day].hour + this.end_time[day].minute;

      if (
        this.start_time[day].hour != 12 &&
        this.start_time[day].amPm == "PM"
      ) {
        st = st + 12 * 60;
      }

      if (this.end_time[day].hour != 12 && this.end_time[day].amPm == "PM") {
        et = et + 12 * 60;
      }

      return st - et;
    },
    addClass(day) {
      this.batchData.schedule[day] = {
        from: this.start_time[day],
        to: this.end_time[day]
      };
      this.timePicker[day] = false;
      this.showRemoveClass[day] = true;
    },
    removeClass(day) {
      this.batchData.schedule[day] = undefined;
      this.showRemoveClass[day] = false;
    },
    showSchedule() {
      this.hideSchedule = false;
      this.compressSchedule = false;
    },
    clearSchedule() {
      this.hideSchedule = true;
      this.compressSchedule = false;
      for (let key of Object.keys(this.showRemoveClass)) {
        this.batchData.schedule[key] = undefined;
        this.showRemoveClass[key] = false;
      }
    },
    toggleSchedule() {
      this.compressSchedule = !this.compressSchedule;
    },
    checkForSchedule() {
      let _schedule = Object.keys(this.batch.schedule);
      if (_schedule.length == 0) {
        let temp = {
          Saturday: undefined,
          Sunday: undefined,
          Monday: undefined,
          Tuesday: undefined,
          Wednesday: undefined,
          Thursday: undefined,
          Friday: undefined
        };
        this.batchData.schedule = temp;
      } else {
        for (let day of _schedule) {
          this.showRemoveClass[day] = !(this.batch.schedule[day] === undefined);
        }
      }
    },
    async getTeacherNames() {
      try {
        this.teacherLoading = true;
        let resp = await this.$store.dispatch("organization/teachers", {
          partner_code: this.$route.params.partner_code
        });
        this.teachers = resp.map(item => {
          return { text: item.name, value: `${item.name}#${item.user_id}` };
        });
        if (this.teachers.length === 1) {
          this.batchData.teacher_id = this.teachers[0].value;
        }
      } catch (e) {
        this.$root.$emit("alert", [undefined, e.message]);
      } finally {
        this.teacherLoading = false;
      }
    },
    async validate() {
      await this.$refs.form.validate();
    }
  }
};
</script>

<style lang="scss" scoped>
.schedule-picker {
  border: 1px solid #c7c7c7 !important;
  border-radius: 5px !important;

  .v-text-field__details {
    display: none;
  }

  .v-menu__content {
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.12) !important;
    border-radius: 5px;
  }

  @media screen and (min-width: 1601px) {
    padding: 0px 15px;
    margin-top: 15px;
  }

  @media screen and (min-width: 600px) and (max-width: 1600px) {
    padding: 0px 10px;
    margin-top: 10px;
  }

  @media screen and (max-width: 599px) {
    padding: 0px 5px;
    margin-top: 10px;
  }
}

.custom-font {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-weight: 500;
}

.header-size {
  @media screen and (min-width: 1601px) {
    font-size: 24px;
  }

  @media screen and (min-width: 600px) and (max-width: 1600px) {
    font-size: 18px;
  }

  @media screen and (max-width: 599px) {
    font-size: 14px;
  }
}

.header-vertical-padding {
  @media screen and (min-width: 1601px) {
    padding: 10px 0px;
    padding-top: 30px;
  }

  @media screen and (min-width: 600px) and (max-width: 1600px) {
    padding: 10px 0px;
    padding-top: 20px;
  }

  @media screen and (max-width: 599px) {
    padding: 0px 0px;
    padding-top: 5px;
  }
}

.avail-sched {
  @media screen and (min-width: 1601px) {
    font-size: 20px;
  }

  @media screen and (min-width: 600px) and (max-width: 1600px) {
    font-size: 16px;
  }

  @media screen and (max-width: 599px) {
    font-size: 12px;
  }
}

.add-cancel-text {
  @media screen and (min-width: 1601px) {
    font-size: 16px;
  }

  @media screen and (min-width: 600px) and (max-width: 1600px) {
    font-size: 14px;
  }

  @media screen and (max-width: 599px) {
    font-size: 11px;
  }
}

.responsive-font {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 16px;
}

.day-text {
  @media screen and (min-width: 1601px) {
    font-size: 18px;
  }

  @media screen and (min-width: 600px) and (max-width: 1600px) {
    font-size: 14px;
  }

  @media screen and (max-width: 599px) {
    font-size: 10px;
  }
}

.v-message {
  display: none;
}

.read-only-class {
  pointer-events: none;
}
</style>
