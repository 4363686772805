<template>
  <div>
    <v-btn
      v-show="!active"
      :disabled="!disabled"
      :outlined="!hasDeactiveIcon"
      depressed
      :color="color"
      :class="['pa-0', { 'white--text': hasDeactiveIcon }]"
      :height="sizeObject.height"
      :width="sizeObject.width"
      :min-width="minWidth"
      @click="activate()"
      ><v-icon>{{ deactiveIcon }}</v-icon></v-btn
    >
    <v-btn
      v-show="active"
      :disabled="!disabled"
      depressed
      :color="color"
      class="pa-0 white--text"
      :height="sizeObject.height"
      :width="sizeObject.width"
      :min-width="minWidth"
      @click="activate()"
      ><v-icon :size="sizeObject.height">{{ activeIcon }}</v-icon></v-btn
    >
  </div>
</template>

<script>
export default {
  name: "MkCheckBox",
  props: {
    disabled: { type: Boolean, default: true },
    color: String,
    sizeObject: Object,
    index: Number,
    minHeight: String,
    minWidth: String,
    active: { type: Boolean, default: false },
    activeIcon: { type: String, default: "" },
    deactiveIcon: { type: String, default: "" }
  },
  data() {
    return {
      hasDeactiveIcon: this.deactiveIcon.length > 0
    };
  },
  methods: {
    activate() {
      this.$emit("click", this.index);
    }
  }
};
</script>
