var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-btn",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.active,
              expression: "!active",
            },
          ],
          class: ["pa-0", { "white--text": _vm.hasDeactiveIcon }],
          attrs: {
            disabled: !_vm.disabled,
            outlined: !_vm.hasDeactiveIcon,
            depressed: "",
            color: _vm.color,
            height: _vm.sizeObject.height,
            width: _vm.sizeObject.width,
            "min-width": _vm.minWidth,
          },
          on: {
            click: function ($event) {
              return _vm.activate()
            },
          },
        },
        [_c("v-icon", [_vm._v(_vm._s(_vm.deactiveIcon))])],
        1
      ),
      _c(
        "v-btn",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.active,
              expression: "active",
            },
          ],
          staticClass: "pa-0 white--text",
          attrs: {
            disabled: !_vm.disabled,
            depressed: "",
            color: _vm.color,
            height: _vm.sizeObject.height,
            width: _vm.sizeObject.width,
            "min-width": _vm.minWidth,
          },
          on: {
            click: function ($event) {
              return _vm.activate()
            },
          },
        },
        [
          _c("v-icon", { attrs: { size: _vm.sizeObject.height } }, [
            _vm._v(_vm._s(_vm.activeIcon)),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }